import { Metadata, User } from './interfaces';
import { CLIENT_NAMES, CLIENTS } from './constants';

export const clientLogoConfig = (clientName: string) => {
  const mappedClientName =
    CLIENT_NAMES.indexOf(clientName) !== -1 ? clientName : CLIENTS.SMARTER_HEALTH;
  return `${window.location.origin}/fss/v1/docs?fileName=/client/${mappedClientName}/site/logo.png`;
};

export const updateUser = (user: User) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const updateMetadata = (
  user: User,
  meta: Metadata,
  getRoleUserDoctor: () => Promise<any>,
) => {
  localStorage.setItem('metadata', JSON.stringify(meta));
  localStorage.setItem('logo', JSON.stringify(clientLogoConfig(meta.clientId)));

  document.cookie = `client=${meta.clientId}; path=/`;
  document.cookie = `clientId=${meta.client}; path=/`;
  document.cookie = `clientCountry=${meta.countryId}; path=/`;
  document.cookie = `userId=${meta.userId}; path=/`;
  document.cookie = `roleId=${meta.roleNames[0]}; path=/`;

  meta.roleNames?.map(async (roleName) => {
    if (
      roleName === 'ROLE_ADMIN' ||
      roleName === 'Specialist' ||
      roleName === 'ROLE_SUPER_ADMIN' ||
      roleName === 'Provider Contract Manager'
    ) {
      let doctor;
      try {
        doctor = await getRoleUserDoctor();
      } catch (err) {
        console.log('Can not get user profile');
      }
      const temp = {
        ...user,
        id: doctor?.data?.content?.doctorId || null,
      };
      localStorage.setItem('user', JSON.stringify(temp)); // for smart contract
    }
  });
};
