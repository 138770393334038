import { Box, Grid, InputLabel, makeStyles } from '@material-ui/core';
import SmarterTextField from '../textfield/TextField';
import ClearIcon from '@material-ui/icons/Clear';

export function FreeText(props) {
  const { label, isRequired, value, onChange, disabled, handleRemoveFreeText, classes } = props;
  const ownClasses = useStyles();
  return (
    <Grid container className={ownClasses.MUIFormItem}>
      <>
        <Grid item xs={12}>
          <InputLabel className={ownClasses.MUIInputLabel} required={isRequired}>
            {label}
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <SmarterTextField
              value={value}
              onChange={onChange}
              disabled={disabled}
              allowClear={true}
              classes={classes}
            />
            <ClearIcon onClick={handleRemoveFreeText} classes={{ root: ownClasses.MUIClearIcon }} />
          </Box>
        </Grid>
      </>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  MUIFormItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1.2, 0),
    marginTop: '10px',
  },
  MUIInputLabel: {
    display: 'inline-block',
    alignItems: 'top',
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(0.8),
    '& .MuiFormLabel-asterisk ': {
      color: 'red',
    },
    position: 'unset',
    transform: 'unset',
  },
  MUIClearIcon: {
    marginLeft: '20px',
    cursor: 'pointer',
  },
}));
