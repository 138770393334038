import { useState } from 'react';
import { useSwitchPayorProps } from './intefaces';
import { updateUser, updateMetadata } from '../Utils/utils';
import { SELECTED_PAYOR_KEY } from '../Utils';

export const GENERAL_ERROR = 'Can not switch to other payor. Please try again later';

export function useSwitchPayor({
  getTokenByPayor,
  getMetadata,
  getRoleUserDoctor,
  errorMsg = GENERAL_ERROR,
  onSuccessSwitchPayor,
}: useSwitchPayorProps) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function handleRefreshMetadata(token: any, selectedPayor: string) {
    try {
      const res = await getMetadata();
      let meta = res.data;
      let user = {
        ...token,
        roles: meta?.roleNames || [],
        id: null,
      };
      updateMetadata(user, meta, getRoleUserDoctor);
      localStorage.setItem(SELECTED_PAYOR_KEY, selectedPayor);
      onSuccessSwitchPayor && onSuccessSwitchPayor();
      setIsLoading(false);
    } catch (err) {
      setError(errorMsg);
      setIsLoading(false);
    }
  }

  function switchPayorAction(selectedPayor: string) {
    if (selectedPayor) {
      setIsLoading(true);
      const payload = {
        payorId: selectedPayor,
      };
      getTokenByPayor(payload)
        .then((res: any) => {
          updateUser(res.data);
          handleRefreshMetadata(res.data, selectedPayor);
        })
        .catch(() => {
          setError(errorMsg);
          setIsLoading(false);
        });
    } else {
      setError(errorMsg);
    }
  }

  return {
    switchPayorAction,
    handleRefreshMetadata,
    error,
    setError,
    isLoading,
  };
}
