import { get, post } from '../HTTPServices /HTTPService';

export async function getMenu(clientId: string, roleName: string) {
  const bearerToken = 'abc123';
  const headerConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  };
  const url = `/tree/v1/payors/${clientId}/roles/attributes/menu?roleName=${roleName}`;
  try {
    const res = await get(url, headerConfig);
    if (res?.data) {
      return res?.data;
    }
  } catch (err: any) {
    throw new Error(err);
  }
}

export async function postLogout(payload: object, token: string = '') {
  const bearerToken = token ? `Bearer ${token}` : 'abc123';
  const headerConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  };
  const url = `/auth/v2/auth/logout`;
  try {
    const res = await post(url, payload, token, headerConfig);
    if (res?.data) {
      return res?.data;
    }
  } catch (err: any) {
    throw new Error(err);
  }
}
