import { makeStyles, LinearProgress, withStyles } from '@material-ui/core';
import { useState, useCallback, useEffect, useRef, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadError from './UploadError';
import { v4 as uuidv4 } from 'uuid';
import FileUploadStatus from './FileUploadStatus';
import { SingleFileUploaded } from './SingleFileUploaded';
import { unstable_batchedUpdates } from 'react-dom';
import { MAX_SIZE, useCallbackRef } from '../../utils';
import { FileUploadProps, MultipleFileUploadProps, UploadFileStatus } from './interfaces';
import clsx from 'clsx';

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const ACCEPTS = {
  IMAGE: {
    'image/*': ['.jpg', '.jpeg', '.gif', '.png'],
  },
  PDF: {
    'application/pdf': ['.pdf'],
  },
  WORD: {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  },
  EXCEL: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
    'text/csv': ['.csv'],
  },
};

const MultipleFileUpload: FC<MultipleFileUploadProps> = ({
  id,
  fileInputProps = {},
  files = [],
  onComplete,
  onError,
  onDelete,
  uploadUrl,
  maxSize = MAX_SIZE,
  single = false,
  requestHeaders = {},
  handleFileUploading,
  className,
  acceptTypes = { ...ACCEPTS.EXCEL, ...ACCEPTS.IMAGE, ...ACCEPTS.PDF, ...ACCEPTS.WORD },
  isLocalPreview,
  onChange,
  CustomUploadComponent,
  isShowSize,
}) => {
  // Always set the state into empty array to avoid upload requesting the files again.
  // "upload request" is depending to this state. If we push a file to this
  // state, parent files state will also be updapted, then the Component will send upload request.
  // So if the Component gets unmounnted and mount again then initialise the
  // state to the prop "files", then the Component will fire a request to each files
  // include to the state. To avoid duplicated request, always init this state to empty array.
  const [ownFiles, setOwnFiles] = useState<{
    status: UploadFileStatus;
    data: any;
  }>({
    status: 'init',
    data: [],
  });
  const classes = useStyles();

  function handleDrop(accFiles, rejFiles) {
    const mappedAcc = accFiles.map((file) => ({
      file,
      errors: [],
      id: uuidv4(),
    }));
    const mappedRej = rejFiles.map((r) => ({ ...r, id: uuidv4() }));
    const newFiles = [...ownFiles.data, ...mappedAcc, ...mappedRej];
    const newOwnFiles = !single ? newFiles : [mappedAcc[0], mappedRej[0]].filter(Boolean);
    setOwnFiles({
      status: 'drop',
      data: newOwnFiles,
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    accept: acceptTypes,
    maxSize,
  });

  function handleDelete(fileWrapper) {
    // delete local file
    setOwnFiles((prev) => ({
      ...prev,
      data: prev.data.filter((fw: any) => fw.id !== fileWrapper.id),
    }));

    // delete uploaded file
    onDelete?.(
      files.filter((f) => f.id !== fileWrapper.id),
      fileWrapper,
    );
  }

  function handleComplete(newFileWrapper) {
    if (!onComplete) {
      return;
    }

    if (!single) {
      onComplete([...files, newFileWrapper], newFileWrapper);
    } else {
      onComplete([newFileWrapper], newFileWrapper);
    }
    setOwnFiles((prev) => ({
      ...prev,
      status: 'complete',
    }));
  }

  function handlePending() {
    setOwnFiles((prev) => ({
      ...prev,
      status: 'pending',
    }));
  }

  function handleError(error) {
    onError(error);
    setOwnFiles((prev) => ({
      ...prev,
      status: 'error',
    }));
  }

  useEffect(() => {
    if (ownFiles?.data && isLocalPreview) {
      const validFiles = ownFiles?.data
        ?.filter((item) => !item?.errors?.length) // Remove error item
        ?.map((item) => item.file); // Get file object
      if (validFiles?.length) {
        onChange?.(validFiles);
      }
    }
  }, [ownFiles, isLocalPreview]);

  return (
    <div id={id} className={className}>
      <div {...getRootProps({})} className={clsx(classes.dropZone, 'FileUpload-DropZone')}>
        <input {...fileInputProps} {...getInputProps()} aria-label="Select file" />
        {CustomUploadComponent ? (
          CustomUploadComponent
        ) : (
          <p className={classes.dropZoneText}>
            Drag file here or <span className={classes.browseText}>Browse</span> file
          </p>
        )}
      </div>
      {single
        ? ownFiles.status === 'init' &&
          files.map((fileWrapper) => (
            <SingleFileUploaded
              key={fileWrapper.id}
              onDelete={() => handleDelete(fileWrapper)}
              file={fileWrapper}
            />
          ))
        : files.map((fileWrapper) => (
            <SingleFileUploaded
              key={fileWrapper.id}
              onDelete={() => handleDelete(fileWrapper)}
              file={fileWrapper.file}
            />
          ))}
      {ownFiles.data.map((fileWrapper) => {
        if (fileWrapper.errors?.length > 0) {
          return (
            <UploadError
              key={fileWrapper.id}
              errors={fileWrapper.errors}
              acceptTypes={acceptTypes}
              file={fileWrapper.file}
              onDelete={() => handleDelete(fileWrapper)}
            />
          );
        }
        return (
          <FileUpload
            isLocalPreview={isLocalPreview}
            onPending={handlePending}
            status={ownFiles.status}
            key={fileWrapper.id}
            url={uploadUrl}
            file={fileWrapper.file}
            onDelete={() => handleDelete(fileWrapper)}
            onComplete={(uploadFile) => {
              handleComplete({
                id: fileWrapper.id,
                ...uploadFile,
              });
            }}
            onError={handleError}
            requestHeaders={requestHeaders}
            single={single}
            handleFileUploading={handleFileUploading}
            isShowSize={isShowSize}
          />
        );
      })}
    </div>
  );
};

const FileUpload: FC<FileUploadProps> = ({
  onPending,
  status,
  file,
  onDelete,
  url,
  onComplete,
  onError,
  requestHeaders = {},
  single = false,
  handleFileUploading,
  isLocalPreview,
  isShowSize,
}) => {
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<UploadFileStatus>('pending');

  // Wrap the handler into ref to avoid "re-uploading" the same file to the server if the handler change its reference object.
  const onPendingRef = useCallbackRef(onPending);
  const onCompleteRef = useCallbackRef(onComplete);
  const onErrorRef = useCallbackRef(onError);
  const statusRef = useRef(status);
  statusRef.current = status;
  const requestHeadersRef = useRef(requestHeaders);

  const serverErrorMesg =
    'Encountered an error when uploading the document. Please try again later.';

  const handleUploadFileError = useCallback((errorType = 'error', meta = {}) => {
    setUploadStatus(errorType);

    let errorMessage = serverErrorMesg;

    if (meta.message) {
      errorMessage = meta.message;
    }

    onErrorRef.current({
      message: errorMessage,
      ...meta,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upload = useCallback(async () => {
    setUploadStatus('pending');

    if (statusRef.current === 'drop') {
      onPendingRef.current?.();

      handleFileUploading?.(
        file,
        setProgress,
        handleUploadFileError,
        url,
        single,
        requestHeadersRef.current,
      )
        .then((uploadedFile) => {
          // TODO:
          // - we need to handle the resolve state for upload word document.
          //   Right now, we only support handling resolve state for
          //   multiple file uploads (Array). We need handle resolve state for single file upload.
          //   Most likely, we if resolve for single file upload, we will invoke the `onCompleteRef.current` callback and pass the
          //   file and also the response value like the `storageFullPath`.
          if (uploadedFile) {
            uploadedFile.file = file;
            unstable_batchedUpdates(() => {
              setUploadStatus('success');
              onCompleteRef.current?.(uploadedFile);
            });
          }
        })
        .catch((error) => {
          handleUploadFileError(error.type, error.meta);
        });
    }
  }, [handleFileUploading, file, handleUploadFileError, url, single]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isLocalPreview && progress < 100) {
      timer = setInterval(() => {
        setProgress((prev) => prev + 25);
      }, 1000);
    } else {
      upload();
    }
    return () => {
      clearInterval(timer);
    };
  }, [upload, isLocalPreview, progress]);

  return (
    <FileUploadStatus
      status={uploadStatus}
      onReupload={upload}
      onDelete={onDelete}
      file={file}
      isShowSize={isShowSize}
    >
      <div style={{ width: '100%' }}>
        <StyledLinearProgress variant="determinate" value={progress} />
      </div>
    </FileUploadStatus>
  );
};

const useStyles = makeStyles((theme) => ({
  dropZone: {
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23C4C4C4FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: '10px',
    paddingTop: '20px',
    paddingBottom: '20px',
    marginBottom: 16,
    cursor: 'pointer',
  },
  dropZoneText: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#737373',
    marginTop: 0,
    marginBottom: 0,
  },
  browseText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const StyledLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '5px',
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
  },
}))(LinearProgress);

export default MultipleFileUpload;
