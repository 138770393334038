import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';

import SmarterTextField from '../textfield/TextField';
import { CustomSearchProps } from './interface';

import SmarterPopover from '../../../Utils/Popover/Popover';
import SearchResult from './SearchResult';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { FreeText } from './FreeText';

const DEFAULT_CODE = {
  code: '',
  description: '',
  otherCode: '',
};

export default function CustomSearch(props: CustomSearchProps) {
  const {
    value,
    onChange,
    disabled,
    allowClear,
    placeholder,
    freeTextLabel,
    data,
    isLoading,
    onSearch,
    isAllowFreeText,
    EmptyComponent,
    classes,
  } = props;
  const customSearchRef = useRef();

  const theme = useTheme();
  const [isVisiblePopover, setIsVisiblePopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [result, setResult] = useState(DEFAULT_CODE);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(false);
  const ownClasses = useStyles();
  const [isShowFreeText, setIsShowValueFreeText] = useState(false);
  const [disableSearchField, setDisableSearchField] = useState(disabled);

  const HelperText = () => {
    if (!searchValue.length || (searchValue.length && searchValue.length < 3)) {
      const color = error ? theme.palette.error.main : theme.palette.primary.main;
      return (
        <span style={{ color }}>
          <i>* Input must be more than 2 characters</i>
        </span>
      );
    }
    return (
      <span style={{ color: theme.palette.primary.main }}>
        <i>* Press Enter button to search diagnosis</i>
      </span>
    );
  };

  function handleSearch(event) {
    if (event && searchValue.length > 2) {
      setIsVisiblePopover(true);
      setAnchorEl(event.currentTarget.firstChild);
      onSearch(searchValue);
    }
    setError(searchValue.length < 3);
  }

  function handleSelect(selected) {
    setResult({ ...selected, otherCode: '' });
    onChange?.({ searchValue, ...selected, otherCode: '', isFreeTextMode: isShowFreeText });
    handleClose();
  }

  function handleClose() {
    setIsVisiblePopover(false);
    setAnchorEl(null);
  }

  function handleChangeInputSearch(newValue) {
    setSearchValue(newValue);
    if (isEmpty(newValue)) {
      setError(false);
      setResult({ ...result, code: '', description: '' });
      onChange({
        ...result,
        searchValue: '',
        code: '',
        description: '',
        isFreeTextMode: isShowFreeText,
      });
    }
  }

  function closeFreeText() {
    if (!disabled) {
      setIsShowValueFreeText(false);
      setDisableSearchField(disabled);
      setResult({ ...result, otherCode: '' });
      onChange({ ...result, otherCode: '', isFreeTextMode: false });
    }
  }

  function openFreeText() {
    setIsShowValueFreeText(true);
    setDisableSearchField(true);
    setResult({
      ...result,
      code: '',
      description: '',
    });
    onChange({ ...result, code: '', description: '', isFreeTextMode: true });
    setSearchValue('');
    setError(false);
  }

  function handleChangeFreeText(value) {
    setResult({ ...result, otherCode: value });
    onChange?.({ ...result, otherCode: value, isFreeTextMode: isShowFreeText });
  }

  useEffect(() => {
    if (typeof value === 'object') {
      if (value?.description || value?.code) {
        setResult({
          code: value.code || '',
          description: value.description || '',
          otherCode: '',
        });
      } else if (value?.otherCode) {
        setResult({ code: '', description: '', otherCode: value.otherCode || '' });
        setIsShowValueFreeText(true);
        setDisableSearchField(true);
        setSearchValue('');
        setError(false);
      }
    }
  }, [value]);

  return (
    <div id={props.id}>
      {isAllowFreeText && (
        <Typography
          classes={{ root: ownClasses.MUILabelFreeText }}
          variant="body1"
          gutterBottom
          onClick={() => !disabled && openFreeText()}
        >
          can't find what you're looking for?
        </Typography>
      )}

      <SmarterTextField
        error={error}
        placeholder={placeholder}
        value={result.description ? `${result.code}: ${result.description}` : searchValue}
        onChange={handleChangeInputSearch}
        disabled={disableSearchField}
        inputRef={customSearchRef}
        onEnter={handleSearch}
        allowClear={allowClear}
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
        helperText={<HelperText />}
        classes={classes}
      />
      {isShowFreeText && (
        <FreeText
          label={freeTextLabel}
          isRequired
          handleRemoveFreeText={closeFreeText}
          onChange={handleChangeFreeText}
          value={result.otherCode}
          classes={classes}
        />
      )}

      <SmarterPopover
        id={isVisiblePopover ? 'simple-popover' : undefined}
        open={isVisiblePopover}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <SearchResult
          data={data}
          searchValue={searchValue}
          select={result}
          onSelect={handleSelect}
          isLoading={isLoading}
          EmptyComponent={EmptyComponent}
        />
      </SmarterPopover>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  MUILabelFreeText: {
    fontWeight: 500,
    fontFamily: 'inherit',
    color: '#03ACEF',
    cursor: 'pointer',
    textAlignLast: 'right',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
