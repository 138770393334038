export const USER_AUTH_STORAGE_KEY = 'user';
export const CLIENT_NAMES = ['SmarterHealth', 'Allianz', 'FWD'];

export const CLIENTS = {
  SMARTER_HEALTH: 'SmarterHealth',
};

export const SELECTED_PAYOR_KEY = 'selectedPayor';

export const USER_PAYORS = 'userPayors';
