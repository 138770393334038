import { SwitchPayorProps } from '../interface';
import { Box, ListItemText, makeStyles, MenuItem, Select } from '@material-ui/core';
import { PALETTE_COLORS } from '../../../utils';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { useState } from 'react';

export default function SwitchPayor(props: SwitchPayorProps) {
  const { payors, selectedPayor, onChange } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event?.target?.value);
  };

  const selectedPayorName = payors?.find((item) => item.id === selectedPayor)?.payorName;

  return (
    <Select
      labelId="switch-payor"
      id="switch-payor"
      value={selectedPayor}
      disableUnderline
      onChange={handleChange}
      autoWidth
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        PopoverClasses: { root: classes.paper, paper: classes.MUIMenuPaper },
      }}
      classes={{
        root: classes.select,
        select: classes.selectComponent,
        selectMenu: classes.selectedMenu,
      }}
      inputProps={{ IconComponent: () => null }}
      renderValue={() => {
        return (
          <Box component="div" display="flex" justifyContent="center" alignItems="center">
            <SwapHorizIcon />
            <ListItemText primary={selectedPayorName} classes={{ primary: classes.listItemText }} />
          </Box>
        );
      }}
    >
      {payors.map((item) => (
        <MenuItem value={item.id} classes={{ root: classes.MUIMenuItem }}>
          {item.payorName}
        </MenuItem>
      ))}
    </Select>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    color: theme.palette.neutral[PALETTE_COLORS.light],
    minWidth: '120px',
    backgroundColor: theme.palette.primary[PALETTE_COLORS.main],
    fontWeight: 200,
    padding: '5px 8px',
    borderStyle: 'none',
    borderWidth: '2px',
    borderRadius: '40px',
    '&:focus': {
      backgroundColor: theme.palette.primary[PALETTE_COLORS.main],
      borderRadius: '40px',
    },
  },
  selectComponent: {
    paddingRight: '0 !important',
  },
  listItemText: {
    fontSize: '14px',
    margin: '0 8px',
    fontWeight: 700,
  },
  paper: {
    transform: 'translateY(8px)',
  },
  MUIMenuPaper: {
    minWidth: '130px',
    maxWidth: '250px',
  },
  MUIMenuItem: {
    fontSize: '14px',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.primary[PALETTE_COLORS.main],
    },
  },
  selectedMenu: {},
}));
