import { FC } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { FileUploadStatusProps } from './interfaces';
import clsx from 'clsx';

const FileUploadStatus: FC<FileUploadStatusProps> = ({
  file,
  onDelete,
  onReupload,
  children,
  isShowSize = true,
  status,
}) => {
  const classes = useStyles();

  const uploadedFileStatuses = {
    pending: `${(file.size / (1024 * 1024)).toFixed(2)} Mb`,
    success: <span className={`${classes.statusText} ${classes.successText}`}>Completed</span>,
    error: <span className={`${classes.statusText} ${classes.failText}`}>Upload Failed</span>,
    errorMaxSizeExceed: (
      <span className={`${classes.statusText} ${classes.failText}`}>Max file size is 5MB</span>
    ),
  };

  return (
    <Grid item>
      <div className={clsx(classes.uploadWrapper, 'FileUploadStatus-wrapper')}>
        {status === 'error' || status === 'errorMaxSizeExceed' ? (
          <Button
            aria-label="Reupload the file"
            size="small"
            onClick={onReupload}
            className={classes.actionButton}
          >
            <ReplayIcon color="error" />
          </Button>
        ) : (
          <Button
            aria-label="Remove uploaded file"
            size="small"
            onClick={() => onDelete?.(file)}
            className={classes.actionButton}
          >
            <CancelOutlinedIcon htmlColor="#ED5E58" />
          </Button>
        )}
        <div className={clsx(classes.uploadInfor, 'FileUploadStatus-infor')}>
          <div className={clsx(classes.titleWrapper, 'FileUploadStatus-titleWrapper')}>
            <span>{file.name}</span>
            {isShowSize && <span>{uploadedFileStatuses[status]}</span>}
          </div>
          {children}
        </div>
      </div>
    </Grid>
  );
};

export default FileUploadStatus;

const useStyles = makeStyles((theme) => ({
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  uploadInfor: {
    flex: 1,
  },
  actionButton: {
    padding: 0,
    minWidth: 'initial',
    marginRight: 24,
  },
  statusText: {
    fontSize: 14,
    lineHeight: '150%',
    textAlign: 'right',
  },
  successText: {
    color: theme.palette.success.main,
  },
  failText: {
    color: theme.palette.error.main,
  },
}));
