import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const LOCATION_ORIGIN = window && window.location && window.location.origin;

/**
 * Axios instance for all request
 * **/
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ARROW_URL || `${LOCATION_ORIGIN}/` || 'http://localhost:8080/',
  xsrfCookieName: process.env.REACT_APP_ARROW_XSRFCOOKIENAME ?? 'XSRF-TOKEN',
  xsrfHeaderName: process.env.REACT_APP_ARROW_XSRFHEADERNAME ?? 'X-XSRF-TOKEN',
  timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT
    ? parseInt(process.env.REACT_AXIOS_REQUEST_TIMEOUT)
    : 30000,
});

const commonHeader = (config = {}) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      ...config,
    },
  };
};

export const getAuthorizeHeader = (token: string, configHeader = {}) => {
  try {
    const authorizeHeader = { Authorization: `Bearer ${token}` };
    const config = {
      headers: {
        ...authorizeHeader,
        ...commonHeader(configHeader).headers,
      },
    };
    return config;
  } catch (err) {
    // TODO: Handle App error handling.
  }
};

/**
 * Get Request With Token
 * **/
export const get = (url: string, config = {}) => {
  return axiosInstance.get(url, config);
};
export const post = (url: string, payload: object, token: string, configHeader = {}) => {
  return axiosInstance.post(url, payload, getAuthorizeHeader(token, configHeader));
};
