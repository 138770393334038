import React, { Ref, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { SmarterTextFieldProps } from './interface';

const useStyles = makeStyles(() => ({
  root: (props: any) => ({
    pointerEvents: props.disabled && 'none',
  }),
  disabled: {
    backgroundColor: '#ECECEC',
  },
  clearIcon: { cursor: 'pointer' },
}));

export default function SmarterTextField({
  inputRef,
  InputProps,
  onEnter,
  value,
  setValue,
  variant = 'outlined',
  suffix = null,
  allowClear = true,
  onChange,
  disabled = false,
  className,
  ...props
}: SmarterTextFieldProps) {
  const [textFieldValue, setTextFieldValue] = useState(value);
  const classes = useStyles({ disabled });
  const handleKeyPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnter && onEnter(event);
    }
  };

  const clearValue = () => {
    //  event.stopPropagation();
    setTextFieldValue('');
    onChange && onChange('');
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextFieldValue(event.target.value || '');
    onChange && onChange(event.target.value || '');
  };

  useEffect(() => {
    setTextFieldValue(value || '');
  }, [value]);

  const computeInputProps = () => {
    if (allowClear && !disabled && !_.isEmpty(textFieldValue)) {
      return {
        endAdornment: <ClearIcon onClick={clearValue} className={classes.clearIcon} />,
      };
    }
    return InputProps;
  };

  return (
    <TextField
      variant={variant}
      fullWidth
      inputRef={inputRef}
      value={textFieldValue}
      onChange={handleOnChange}
      disabled={disabled}
      onKeyPress={handleKeyPressEnter}
      InputProps={{ ...computeInputProps(), classes: { disabled: classes.disabled } }}
      className={`${classes.root} ${className}`}
      {...props}
    />
  );
}
