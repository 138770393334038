import { makeStyles } from '@material-ui/core/styles';
import VirtualizedTree from '../../../VirtualizedTree';
import { Skeleton } from '@material-ui/lab';
import { SearchResultProps, SmarterEmptyProps } from './interface';
import { Empty } from './Empty';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: theme.spacing(30),
  },
  wrapper: (props: any) => ({
    width: '100%',
    height: theme.spacing(30),
    display: props.loading && 'flex',
    flexDirection: 'column',
    justifyContent: props.loading && 'center',
    alignItems: props.loading && 'center',
  }),
  content: { width: '100%', height: theme.spacing(30) },
  emptyRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginTop: '10px',
  },
}));

export default function SearchResult({
  onSelect,
  searchValue,
  isShowCode,
  data,
  isLoading,
  EmptyComponent,
}: SearchResultProps) {
  const classes = useStyles({ isLoading });
  const handleOnClick = (code, description) => {
    onSelect({ code, description });
  };

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <>
          <Skeleton animation="wave" height={40}></Skeleton>
          <Skeleton animation="wave" height={40} style={{ marginLeft: '40px' }}></Skeleton>
          <Skeleton animation="wave" height={40} style={{ marginLeft: '40px' }}></Skeleton>
          <Skeleton animation="wave" height={40}></Skeleton>
          <Skeleton animation="wave" height={40} style={{ marginLeft: '40px' }}></Skeleton>
          <Skeleton animation="wave" height={40} style={{ marginLeft: '40px' }}></Skeleton>
          <Skeleton animation="wave" height={40}></Skeleton>
          <Skeleton animation="wave" height={40}></Skeleton>
        </>
      )}
      {!isLoading && data.length === 0 && <SmarterEmpty CustomComponent={EmptyComponent} />}
      {!isLoading && data.length !== 0 && (
        <div className={classes.content}>
          <p style={{ opacity: '0.7' }}>
            <i>* Please choose diagnosis</i>
          </p>
          <VirtualizedTree
            items={data}
            highlight={searchValue}
            onRowClick={handleOnClick}
            showCode={isShowCode}
            height={300}
          />
        </div>
      )}
    </div>
  );
}

const SmarterEmpty = ({ CustomComponent }: SmarterEmptyProps) => {
  const classes = useStyles({});

  if (CustomComponent) {
    return <CustomComponent />;
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.emptyRoot}>
          <Empty />
          <div className={classes.text}>No Data</div>
        </div>
      </div>
    );
  }
};
