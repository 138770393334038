import { FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import { AlertProps } from './interface';

const Alert: FC<AlertProps> = ({
  classes = {},
  severity = 'success',
  variant = 'outlined',
  fullWidth = false,
  duration,
  onHideAlert,
  ...props
}) => {
  const ownClasses = useAlertStyles();

  // Hide the alert after duration time
  useEffect(() => {
    let timer;
    if (duration && onHideAlert && duration > 0) {
      timer = setTimeout(() => {
        onHideAlert?.();
      }, duration);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [duration, onHideAlert]);

  return (
    <MuiAlert
      severity={severity}
      variant={variant}
      classes={{
        ...classes,
        root: `${classes?.root} ${ownClasses.alert} ${fullWidth ? ownClasses.fullWidth : ''}`,
        icon: `${classes?.icon} ${ownClasses.alertIcon}`,
        message: `${classes?.message} ${ownClasses.alertMessage}`,
        outlinedSuccess: ownClasses.outlinedSuccess,
      }}
      {...props}
    />
  );
};

const useAlertStyles = makeStyles(() => ({
  alert: {
    borderRadius: 77,
    padding: '12px 16px',
  },
  alertIcon: {
    padding: 0,
  },
  alertMessage: {
    flex: 1,
    fontSize: 16,
    padding: 0,
    fontWeight: 500,
  },
  outlinedSuccess: {
    border: '2px solid #82DE4B',
    color: '#62BE37',
  },
  fullWidth: {
    width: '100%',
  },
}));

export default Alert;
