import { get } from '../HTTPServices /HTTPService';
import Cookies from 'universal-cookie';
import { equalsIgnoreCase } from '../Compare';

export const GET_DATA_FROM = {
  COOKIES: 'COOKIES',
  LOCAL_STORAGE: 'LOCAL_STORAGE',
  API_METADATA: 'API_METADATA',
};
//roleType values
export const LIAISON_MANAGER = 'Liaison Manager';

export const SPECIALIST = 'Specialist';

export const PATIENT = 'Patient';

export const PAYOR_PAF_MANAGER = 'Payor Paf Manager';

export const PAYOR_PAF_MAKER = 'Payor Paf Maker';

export const PAYOR_PAF_CHECKER = 'Payor PAF Checker';

export const PAYOR_PAF_REVIEWER = 'Payor PAF Reviewer'; // Maker role for FWD

export const PROVIDER_PAF_CREATOR = 'Provider Paf Creator';

function isEmpty(input: any) {
  if (typeof input === 'object') {
    return Object.keys(input).length === 0;
  }
  return !input;
}

export function getDataFromCookies() {
  try {
    const cookies = new Cookies();
    return cookies.getAll() || {};
  } catch (err: any) {
    throw new Error(err);
  }
}
export function getDataFromLocalStorage() {
  try {
    const localString = window.localStorage.getItem('metadata') || '';
    const metadata = localString && JSON.parse(localString);
    return metadata || {};
  } catch (err: any) {
    throw new Error(err);
  }
}

export async function getMetadataFromAPI(token: string) {
  const bearerToken = token ? `Bearer ${token}` : '';
  const headerConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  };
  const url = '/users/v2/user/metadata';
  try {
    const res = await get(url, headerConfig);
    if (res?.data) {
      window.localStorage.setItem('metadata', JSON.stringify(res?.data));
      return res?.data;
    }
  } catch (err: any) {
    throw new Error(err);
  }
}

export interface MetadataProps {
  key?: string;
  token?: string;
  handleChangeCookies?: any;
}

export async function getMetadata({ key, token }: MetadataProps) {
  let result: any = {
    value: '',
    getDataFrom: '',
  };
  let metadata: any = {};
  if (key && typeof key !== 'string') {
    throw new Error('Key should be string value');
  }
  // Get data from cookies
  metadata = getDataFromCookies();
  result = {
    value: key ? metadata[key] || '' : metadata,
    getDataFrom: GET_DATA_FROM.COOKIES,
  };
  if (isEmpty(result.value)) {
    metadata = getDataFromLocalStorage();
    result = {
      value: key ? metadata[key] || '' : metadata,
      getDataFrom: GET_DATA_FROM.LOCAL_STORAGE,
    };
  }
  if (isEmpty(result.value) && token) {
    const metadata = (await getMetadataFromAPI(token)) || {};
    result = {
      value: key ? metadata[key] || '' : metadata,
      getDataFrom: GET_DATA_FROM.API_METADATA,
    };
  }
  const roleId = Object.hasOwn(metadata, 'roleId') ? metadata['roleId']?.toString() : null;
  if (roleId) {
    const isMaker = equalsIgnoreCase(roleId, PAYOR_PAF_MAKER);
    const isDoctor = equalsIgnoreCase(roleId, SPECIALIST);
    const isPatient = equalsIgnoreCase(roleId, PATIENT);
    const isReviewer = equalsIgnoreCase(roleId, PAYOR_PAF_REVIEWER);
    const isChecker = equalsIgnoreCase(roleId, PAYOR_PAF_CHECKER);
    const isManager = equalsIgnoreCase(roleId, PAYOR_PAF_MANAGER);
    const isProvider = equalsIgnoreCase(roleId, LIAISON_MANAGER);
    const isProviderPafCreator = equalsIgnoreCase(roleId, PROVIDER_PAF_CREATOR);
    result.role = {
      isMaker,
      isDoctor,
      isPatient,
      isReviewer,
      isChecker,
      isManager,
      isProvider,
      isProviderPafCreator,
    };
  }

  return result;
}
