import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type LayoutWithStepperProps = {
  title?: string;
  description: string;
  children: JSX.Element;
  classes?: any;
};
export function LayoutWithStepper(props: LayoutWithStepperProps) {
  const { title, description = '', classes = {} } = props;
  const ownClasses = useLayoutWithStepperStyles();

  return (
    <div
      className={clsx({
        [ownClasses.wrapper]: true,
        [classes?.wrapper]: classes?.wrapper,
      })}
      {...props}
    >
      <header className={ownClasses.header}>
        <Typography align="center" className={ownClasses.title}>
          {title}
        </Typography>
        <Typography align="center" display="block" className={ownClasses.description}>
          {description}
        </Typography>
      </header>
      {props.children}
    </div>
  );
}

const useLayoutWithStepperStyles = makeStyles((theme: any) => ({
  header: {
    marginBottom: theme.spacing(5),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  title: {
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.fontSizes.m4,
    lineHeight: theme.typography.h4.lineHeight,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2.5),
  },
  description: {
    fontSize: theme.typography.fontSizes.s3,
    lineHeight: theme.typography.subtitle2.lineHeight,
    fontWeight: theme.typography.fontWeightRegular,
  },
  wrapper: {
    maxWidth: '1500px',
    margin: 'auto',
  },
}));
