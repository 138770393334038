import { FC } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { NoPermissionBase64 } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins, sans-serif !important',
  },
  image: {
    height: '180px',
  },
  title: {
    fontWeight: 700,
    fontSize: `${theme.spacing(4.2)}px`,
    lineHeight: `${theme.spacing(5.6)}px`,
    margin: `${theme.spacing(3.5)}px 0px`,
    fontFamily: 'Playfair Display, sans-serif !important',
  },
  content: {
    fontWeight: 400,
    fontSize: `${theme.spacing(2.2)}px`,
    lineHeight: `${theme.spacing(3.3)}px`,
    margin: '0 0 45px 0',
  },
  button: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(6)}px`,
    fontWeight: 500,
    fontSize: `${theme.spacing(1.8)}px`,
  },
}));

export interface NoPermissionProps {
  isOkta?: boolean;
  onBack?: () => void;
  backToText?: string;
}

const NoPermission: FC<NoPermissionProps> = ({ isOkta, backToText, onBack }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={NoPermissionBase64} alt="403" className={classes.image} />
      <Typography className={classes.title}>Oops...!</Typography>
      <p className={classes.content}>
        Looks like you don’t have the permission to access this page.
      </p>
      {!isOkta && (
        <Button
          data-testid="noPermission_backButton"
          style={{ backgroundColor: '#161616', color: '#fff' }}
          onClick={onBack}
          className={classes.button}
        >
          {backToText}
        </Button>
      )}
    </div>
  );
};

export default NoPermission;
