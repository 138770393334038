import { FC, Fragment, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { INode } from './Node';

const useStyles = makeStyles((theme) => ({
  treeNode: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'start',
    padding: '4px',
  },
  itemBorder: {
    width: '2px',
    float: 'left',
    display: 'block',
    marginLeft: '15px',
    marginRight: '2px',
    borderLeft: '1px dashed rgba(3, 172, 239, 0.4)',
  },
  highlight: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  label: {
    width: '100%',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'rgba(3, 172, 239, 0.08)',
    },
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  icon: {
    paddingTop: '2px',
  },
  leaf: {
    paddingLeft: '10px',
  },
}));

interface RowProps {
  style: any;
  onExpand: (item, index: number) => void;
  index: number;
  highlight?: string;
  showCode?: boolean;
  onClick: (code: string, des: string) => void;
  item: INode;
  setSize: (index: number, size: number | undefined) => void;
}
const Row: FC<RowProps> = ({
  item,
  style,
  index,
  onExpand,
  highlight = '',
  onClick,
  showCode,
  setSize,
}) => {
  const classes = useStyles();
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSize(index, rowRef?.current?.getBoundingClientRect()?.height);
  }, [setSize, index]);
  const lines = () => {
    const { height } = style;
    const { level, id, isLastNode, isLastBranch } = item;
    const numOfFullLines = level - 2;
    let levels: number[] = [];
    if (numOfFullLines > 0) {
      levels = Array.from(new Array(numOfFullLines).keys());
    }
    const vLineStyle = {
      height: `${height}px`,
    };
    let firstVLineStyle: any = { ...vLineStyle };
    const lastVLineStyle = { ...vLineStyle };
    if (isLastBranch) {
      firstVLineStyle = { ...firstVLineStyle, background: 'none' };
    }
    if (isLastNode) {
      lastVLineStyle.height = `${height / 2}px`;
    }
    return (
      <Fragment>
        {level > 1 && <div className={classes.itemBorder} style={firstVLineStyle} />}

        {levels.map((i) => (
          <div key={`${id}-${i}`} className={classes.itemBorder} style={vLineStyle} />
        ))}

        <div className={classes.itemBorder} style={lastVLineStyle} />
      </Fragment>
    );
  };

  const expandButton = () => {
    const { isLeaf, isOpen } = item;
    if (isLeaf) {
      return <span className={classes.leaf} />;
    }
    return (
      <div onClick={handleExpand} className={classes.icon}>
        {isOpen ? (
          <RemoveIcon fontSize="medium" color="primary" />
        ) : (
          <AddIcon fontSize="medium" color="primary" />
        )}
      </div>
    );
  };

  const label = () => (
    <div
      ref={rowRef}
      className={classes.label}
      onClick={(event) =>
        handleOnClick(
          event,
          item?.nodeDetails?.icd10Code || item?.nodeDetails?.code,
          item?.nodeDetails?.icd10Description || item?.nodeDetails?.description,
        )
      }
      role={item?.nodeDetails?.icd10Code || item?.nodeDetails?.code}
      dangerouslySetInnerHTML={{ __html: computeLabel(item?.nodeDetails) }}
    />
  );

  const handleExpand = () => {
    onExpand(item, index);
  };

  const highlightSearchKeys = (fullText: string, searchString: string) => {
    const arraySearchString = searchString.trim().split(' ');
    arraySearchString.forEach((keyword: string) => {
      const regex = new RegExp(keyword, 'gi');
      fullText = fullText.replace(regex, `<span class=${classes.highlight}>${keyword}</span>`);
    });
    return fullText;
  };

  const handleOnClick = (event, code, description) => {
    event.preventDefault();
    const temp = showCode ? `${code}: ${description}` : description || code;
    onClick && onClick(code, temp);
  };

  const computeLabel = (nodeDetail) => {
    const { icd10Code, icd10Description, code, description } = nodeDetail;
    const result = showCode
      ? `${icd10Code || code}: ${icd10Description || description}`
      : icd10Description || description || icd10Code || code;
    return highlightSearchKeys(result, highlight);
  };

  return (
    <div className={classes.treeNode} style={style}>
      {lines()}
      {expandButton()}
      {label()}
    </div>
  );
};

export default Row;
