import { createTheme } from '@material-ui/core/styles';
import { PALETTE_COLORS } from '../utils/constants';

export const palette = {
  primary: {
    [PALETTE_COLORS.main]: '#4AAAE9',
    [PALETTE_COLORS.light]: '#D5FCFD',
    [PALETTE_COLORS.dark]: '#180C78',
    [PALETTE_COLORS.lightTransparent]: '#E3F6FF',
    [PALETTE_COLORS['100']]: '#D5FCFD',
    [PALETTE_COLORS['200']]: '#ADF3FB',
    [PALETTE_COLORS['300']]: '#6ACCF1',
    [PALETTE_COLORS['400']]: '#4AAAE9',
    [PALETTE_COLORS['500']]: '#3784C7',
    [PALETTE_COLORS['600']]: '#2764A7',
    [PALETTE_COLORS['700']]: '#231495',
    [PALETTE_COLORS['800']]: '#180C78',
  },
  secondary: {
    [PALETTE_COLORS.main]: '#F37D2A',
    [PALETTE_COLORS.light]: '#FBEED7',
    [PALETTE_COLORS.dark]: '#6B2212',
    [PALETTE_COLORS['100']]: '#FBEED7',
    [PALETTE_COLORS['200']]: '#F7DBAF',
    [PALETTE_COLORS['300']]: '#F3C188',
    [PALETTE_COLORS['400']]: '#EBA96A',
    [PALETTE_COLORS['500']]: '#F37D2A',
    [PALETTE_COLORS['600']]: '#C26431',
    [PALETTE_COLORS['700']]: '#813219',
    [PALETTE_COLORS['800']]: '#6B2212',
  },
  success: {
    [PALETTE_COLORS.main]: '#82DE4B',
    [PALETTE_COLORS.light]: '#EFFDDA',
    [PALETTE_COLORS.dark]: '#2D8018',
    [PALETTE_COLORS['100']]: '#EFFDDA',
    [PALETTE_COLORS['200']]: '#DEFBB9',
    [PALETTE_COLORS['300']]: '#AAEB77',
    [PALETTE_COLORS['400']]: '#AAEB77',
    [PALETTE_COLORS['500']]: '#82DE4B',
    [PALETTE_COLORS['600']]: '#62BE37',
    [PALETTE_COLORS['700']]: '#459F24',
    [PALETTE_COLORS['800']]: '#2D8018',
  },
  error: {
    [PALETTE_COLORS.main]: '#FF0800',
    [PALETTE_COLORS.light]: '#FEDDCA',
    [PALETTE_COLORS.dark]: '#940020',
    [PALETTE_COLORS['100']]: '#FEDDCA',
    [PALETTE_COLORS['200']]: '#FDB698',
    [PALETTE_COLORS['300']]: '#FF8465',
    [PALETTE_COLORS['400']]: '#FE553E',
    [PALETTE_COLORS['500']]: '#FF0800',
    [PALETTE_COLORS['600']]: '#D9000B',
    [PALETTE_COLORS['700']]: '#B60019',
    [PALETTE_COLORS['800']]: '#940020',
  },
  grey: {
    500: 'rgba(0,0,0,0.5)',
  },
  neutral: {
    [PALETTE_COLORS.main]: '#7F7F7F',
    [PALETTE_COLORS.light]: '#F2F2F2',
    [PALETTE_COLORS.dark]: '#262626',
    [PALETTE_COLORS['100']]: '#F2F2F2',
    [PALETTE_COLORS['200']]: '#D9D9D9',
    [PALETTE_COLORS['300']]: '#BFBFBF',
    [PALETTE_COLORS['400']]: '#A6A6A6',
    [PALETTE_COLORS['500']]: '#7F7F7F',
    [PALETTE_COLORS['600']]: '#595959',
    [PALETTE_COLORS['700']]: '#404040',
    [PALETTE_COLORS['800']]: '#262626',
  },
  shades: {
    [PALETTE_COLORS.transparent]: 'rgba(0,0,0,0.5)',
    [PALETTE_COLORS.main]: '#7F7F7F',
    [PALETTE_COLORS.light]: '#F2F2F2',
    [PALETTE_COLORS.dark]: '#262626',
    [PALETTE_COLORS['100']]: '#FFFFFF',
    [PALETTE_COLORS['200']]: '#FAFAFA',
    [PALETTE_COLORS['300']]: '#F5F4F8',
    [PALETTE_COLORS['400']]: '#000000',
  },
};

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    success?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    bobby?: PaletteColorOptions;
    neutral?: PaletteColorOptions;
    shades?: PaletteColorOptions;
  }
  interface Palette {
    neutral: any;
    shades: any;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    fontSizes: TypographyStyleOptions;
  }

  interface TypographyOptions {
    fontSizes: TypographyStyleOptions;
  }
}

export const typography = {
  type: {
    primary: '"Poppins", "Helvetica Neue", sans-serif',
    secondary: ' "Playfair Display", "Helvetica Neue", sans-serif',
  },
  weight: {
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
};

const Theme = createTheme({
  palette: {
    ...palette,
  },
  spacing: 10,
  typography: {
    fontFamily: ['Poppins', 'Helvetica Neue', 'sans-serif'].join(','),
    fontSizes: {
      s0: 10,
      s1: 12,
      s2: 14,
      s3: 16,
      s4: 18,
      m1: 20,
      m2: 24,
      m3: 28,
      m4: 30,
      l1: 32,
      l2: 40,
      l3: 48,
    },
  },
});

export default Theme;
