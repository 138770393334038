import { Grid, Button, makeStyles } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import clsx from 'clsx';

const useStyles = makeStyles({
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadInfor: {
    flex: 1,
  },
  actionButton: {
    padding: 0,
    minWidth: 'initial',
    marginRight: 24,
  },
  statusText: {
    fontSize: 14,
    lineHeight: '150%',
    textAlign: 'right',
  },
  failText: {
    color: '#ED5E58',
  },
});

export function SingleFileUploaded({ file, onDelete }) {
  const classes = useStyles();
  return (
    <Grid item>
      <div className={clsx(classes.uploadWrapper, 'SingleFileUpload-wrapper')}>
        <Button size="small" onClick={() => onDelete(file)} className={classes.actionButton}>
          <CancelOutlinedIcon htmlColor="#ED5E58" />
        </Button>
        <div className={clsx(classes.uploadInfor, 'SingleFileUpload-infor')}>
          <div className={classes.titleWrapper}>
            <span>
              {file.fileName}.{file.fileExtension}
            </span>
            <span className={`${classes.statusText}`}>Uploaded</span>
          </div>
        </div>
      </div>
    </Grid>
  );
}
