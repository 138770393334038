import React, { Children, cloneElement } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

/**
 * The Stack component manages layout of immediate children along the vertical or horizontal
 * axis with optional spacing and/or dividers between each child.
 *
 * Example:
 *
 *  <Stack spacing={3}>
 *    <div>hello world</div>
 *     <div>hello world</div>
 *     <div>hello world</div>
 *   </Stack>
 */

interface StackProps<C extends React.ElementType = 'div'> {
  component?: C;
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';
  divider?: JSX.Element;
  spacing?: number;
  children: React.ReactNode;
  className?: string;
}
type Direction = 'column-reverse' | 'column' | 'row-reverse' | 'row';

export default function Stack<C extends React.ElementType = 'div'>({
  component,
  direction = 'column',
  divider,
  spacing = 1,
  children,
  className,
  ...props
}: StackProps<C> & Omit<React.ComponentPropsWithoutRef<C>, keyof StackProps<C>>) {
  const Component = component ?? 'div';
  const theme = useTheme();
  const itemMargin = createMarginBasedOnDirection(direction, theme.spacing(spacing));
  const classes = useStackStyles({ direction, itemMargin });

  return (
    <Component {...props} className={clsx(classes.root, className)}>
      {divider ? joinChildren(children, divider) : children}
    </Component>
  );
}

type StyleProps = {
  direction: Direction;
  itemMargin: string;
};
const useStackStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: ({ direction }: StyleProps) => direction,

    '& > :not(style) + :not(style)': {
      margin: ({ itemMargin }: StyleProps) => itemMargin,
    },
  },
});

/**
 * Return an array with the separator React element interspersed between
 * each React node of the input children.
 *
 * > joinChildren([1,2,3], 0)
 * [1,0,2,0,3]
 */
function joinChildren(children: React.ReactNode, separator: JSX.Element) {
  const childrenArray = Children.toArray(children).filter(Boolean);

  return childrenArray.reduce((output, child, index) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    output.push(child);

    if (index < childrenArray.length - 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output.push(cloneElement(separator, { key: `separator-${index}` }));
    }

    return output;
  }, []);
}

export const createMarginBasedOnDirection = (direction: Direction, size: number) => {
  return {
    row: `0 0 0 ${size}px`,
    'row-reverse': `0 ${size}px 0 0`,
    column: `${size}px 0 0 0`,
    'column-reverse': `0 0 ${size}px 0`,
  }[direction];
};
