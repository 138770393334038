import { Grid, Button, makeStyles } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { FC } from 'react';
import { Accept, ErrorCode } from 'react-dropzone';
import { UploadErrorProps } from './interfaces';

const useStyles = makeStyles({
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadInfor: {
    flex: 1,
  },
  actionButton: {
    padding: 0,
    minWidth: 'initial',
    marginRight: 24,
  },
  statusText: {
    fontSize: 14,
    lineHeight: '150%',
    textAlign: 'right',
  },
  failText: {
    color: '#ED5E58',
  },
});

const getErrorMessage = (err: { code: string; message: string }, acceptTypes: Accept) => {
  if (err.code === ErrorCode.FileTooLarge) {
    return 'Files should be less than 5MB';
  } else if (err.code === ErrorCode.FileInvalidType) {
    const acceptedFileTypes = Object.entries(acceptTypes).map(([_, value]) =>
      value.map((data) => ` ${data}`),
    );
    return `Only these files types are allowed: ${acceptedFileTypes}`;
  } else {
    return err.message;
  }
};

const UploadError: FC<UploadErrorProps> = ({ file, onDelete, errors, acceptTypes }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <div className={classes.uploadWrapper}>
        <Button size="small" onClick={() => onDelete(file)} className={classes.actionButton}>
          <CancelOutlinedIcon htmlColor="#ED5E58" />
        </Button>
        <div className={classes.uploadInfor}>
          <div className={classes.titleWrapper}>
            <span>{file.name}</span>
            <span className={`${classes.statusText} ${classes.failText}`}>Upload Failed</span>
          </div>
        </div>
      </div>
      {errors.map((error) => (
        <div key={error.code}>
          <span style={{ color: 'red' }}>{getErrorMessage(error, acceptTypes)}</span>
        </div>
      ))}
    </Grid>
  );
};

export default UploadError;
