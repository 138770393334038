import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Pagination from '../../components/Pagination';
import { PALETTE_COLORS } from '../../utils';
import { PaginationProps } from '@material-ui/lab';

interface StyleProps {
  leftHeader?: JSX.Element;
}
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: `${theme.typography.fontSizes.s1}px`,
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: `${theme.typography.fontSizes.s4}px`,
    marginBottom: theme.spacing(5.5),
  },
  title: {
    marginRight: theme.spacing(5),
    color: 'rgba(196, 196, 196, 1)',
    cursor: 'pointer',
  },
  active: {
    color: theme.palette.shades[PALETTE_COLORS.dark],
    borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    fontWeight: 500,
  },
  header: {
    display: 'flex',
    justifyContent: (props: StyleProps) => (props.leftHeader ? 'space-between' : 'flex-end'),
    marginBottom: theme.spacing(2),
  },
  sort: {},
  activeSort: {
    color: theme.palette.primary.main,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
  content: {
    width: '100%',
    backgroundColor: theme.palette.shades[PALETTE_COLORS['100']],
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 2px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 6%)`,
  },
}));

interface ITabItem {
  title: string;
  description: string;
}

type LayoutWithTabProps = {
  tabs: Array<ITabItem>;
  classes?: any;
  activeTab: number;
  onClickTabTitle: (activeTab: number) => void;
  leftHeader?: JSX.Element;
  rightHeader?: JSX.Element;
  children: JSX.Element;
  variant: JSX.Element;
  pagination?: PaginationProps;
  itemProps: any;
  application: string;
  showSort: boolean;
};

export default function LayoutWithTab({
  tabs,
  classes,
  activeTab,
  onClickTabTitle,
  leftHeader,
  rightHeader,
  application,
  showSort,
  ...props
}: LayoutWithTabProps) {
  const ownClasses = useStyles({ leftHeader });

  return (
    <div
      className={clsx({
        [ownClasses.wrapper]: true,
        [classes?.wrapper]: classes?.wrapper,
      })}
      id={`${application}_layout-with-tabs`}
    >
      <div id={`${application}-tab`} className={ownClasses.tabs}>
        {tabs.map((tab, index) => (
          <div
            className={clsx({
              [ownClasses.title]: true,
              [ownClasses.active]: activeTab - 1 === index,
            })}
            onClick={() => onClickTabTitle(index + 1)}
            key={`tab-${tab.title}`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div>
        <div className={ownClasses.header}>
          {leftHeader && <>{leftHeader}</>}
          {rightHeader && <>{rightHeader}</>}
        </div>
        {props.children}
        <div className={ownClasses.pagination}>
          <Pagination {...props.pagination} />
        </div>
      </div>
    </div>
  );
}
