import { useState, ReactNode } from 'react';
import { List, ListItem, Collapse, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { PALETTE_COLORS } from '../../utils';

type RenderLink = ({
  url,
  children,
  className,
  activeClassName,
}: {
  url: string;
  children: ReactNode;
  className?: string;
  activeClassName?: string;
}) => JSX.Element;
type SidebarClassKey = 'container' | 'backdrop';

export type SidebarProps = {
  menus?: SidebarItemProps[];
  renderLink?: RenderLink;
  onBackdropClick?: () => void;
  classes?: Partial<{
    [key in SidebarClassKey]: string;
  }>;
  open?: boolean;
  disableFixedPosition?: boolean;
  onClose?: () => void;
};

export default function Sidebar({
  menus = [],
  onBackdropClick,
  classes = {},
  open = false,
  disableFixedPosition = false,
  renderLink,
  onClose,
}: SidebarProps) {
  const ownClasses = useSidebarStyles({ open });
  return (
    <nav>
      <List
        disablePadding
        dense
        className={clsx(
          {
            [ownClasses.sidebarContainer]: true,
            [ownClasses.fixedSidebarContainer]: !disableFixedPosition,
          },
          classes.container,
        )}
      >
        {menus?.map((sidebarItem, index) => (
          <SidebarItem
            onClick={onClose}
            renderLink={renderLink}
            {...sidebarItem}
            key={'list-item-' + index}
          />
        ))}
      </List>
      {open && (
        <div className={clsx(ownClasses.backdrop, classes.backdrop)} onClick={onBackdropClick} />
      )}
    </nav>
  );
}

const useSidebarStyles = makeStyles((theme) => ({
  sidebarContainer: {
    width: (props: any) => (props.open ? '300px' : 0),
    padding: (props: any) =>
      props.open ? `${theme.spacing(2.5)}px ${theme.spacing(4)}px 0 ${theme.spacing(5)}px` : '',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    boxShadow: `0px ${theme.spacing(1.5)} ${theme.spacing(2.5)} rgba(0, 0, 0, 0.15)`,
    transition: 'all .2s linear',
  },
  fixedSidebarContainer: {
    position: 'fixed',
    overflow: 'auto',
    zIndex: 20,
    top: '65px',
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1,
    inset: '0px',
    backgroundColor: theme.palette.shades[PALETTE_COLORS.transparent],
  },
}));

interface SidebarItemProps {
  label: string;
  url?: string;
  menus?: SidebarItemProps[];
  renderLink?: RenderLink;
  onClick?: () => void;
}
function SidebarItem({ label, url = '', menus = [], renderLink, onClick }: SidebarItemProps) {
  const classes = useSidebarItemStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ListItem onClick={onClick} dense component="li" className={classes.listItem} disableGutters>
      {menus.length > 0 ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            component="button"
            className={classes.toggleButton}
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            <span>{label}</span>
            <i className={classes.toggleIcon}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</i>
          </Box>
          <Collapse in={isOpen}>
            <List disablePadding dense className={classes.nestedList}>
              {menus.map((item, idx) => (
                <SidebarItem onClick={onClick} key={idx} {...item} renderLink={renderLink} />
              ))}
            </List>
          </Collapse>
        </>
      ) : renderLink ? (
        renderLink({
          url,
          children: label,
          className: classes.link,
          activeClassName: classes.link__active,
        })
      ) : (
        label
      )}
    </ListItem>
  );
}

const useSidebarItemStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: theme.spacing(2.5),
    display: 'block',
  },
  nestedList: {
    paddingLeft: `${theme.spacing(2.5)}px`,
  },
  toggleButton: {
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    width: '100%',
  },
  toggleIcon: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.shades.dark,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  link__active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium as any,
  },
}));
