import { makeStyles } from '@material-ui/core/styles';

export default function Footer() {
  const classes = useStyles();
  return <div className={classes.footer}>powered by Smarter Health Pte. Ltd.</div>;
}
const useStyles = makeStyles((theme) => ({
  footer: {
    left: '0',
    color: 'white',
    right: '0',
    width: '100%',
    bottom: '0',
    height: theme.spacing(3.1),
    display: 'flex',
    zIndex: 100,
    position: 'fixed',
    fontSize: theme.spacing(1.2),
    textAlign: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    justifyContent: 'center',
    fontFamily: theme.typography.body2.fontFamily || theme.typography.fontFamily,
    backgroundColor: '#202223',
  },
}));
