import { getMetadata } from '../Metadata';
import { useEffect, useState } from 'react';

export interface PermissionProps {
  action?: string;
  resource?: string;
  userPermissions: any;
}

export interface UsePermissionsProps {
  action?: string;
  resource?: string;
  permissions?: any;
}

export async function getAllPermissions() {
  let permissions: any[] = [];
  try {
    const meta = await getMetadata({ key: 'rolePermissions' });
    meta?.value?.forEach((item: any) => (permissions = [...permissions, ...item?.permissions]));
  } catch {}

  return permissions;
}

export async function hasPermission({ action, resource, userPermissions }: PermissionProps) {
  const userHasPermission = userPermissions?.find((obj: any) => {
    return obj.action === action && obj.resource === resource;
  });

  return !!userHasPermission;
}

export function usePermissions({ action, resource, permissions }: UsePermissionsProps) {
  const [permission, setPermission] = useState({});

  async function getRolePermissions() {
    const userPermissions = await getAllPermissions();
    if (action && resource) {
      const result = await hasPermission({ action, resource, userPermissions });
      setPermission(result);
    } else {
      let rolePerms: any = {};
      for (const key in permissions) {
        const result = await hasPermission({ ...permissions[key], userPermissions });
        rolePerms[key] = result;
      }
      setPermission(rolePerms);
    }
  }

  useEffect(() => {
    getRolePermissions();
  }, []);

  return { permission };
}
