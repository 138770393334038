import { SmarterModalProps } from './interface';
import {
  Box,
  DialogActions,
  DialogTitle,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { generateClassName } from '../../../utils/utils';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  MUICloseIcon: {},
  MUIPaper: {
    width: '650px',
    margin: 'auto',
    transform: 'translateY(40%)',
  },
  MUIBox: ({ closeable }: any) => ({
    padding: closeable ? '10px 80px 40px 80px' : '40px 80px',
  }),
  MUIWarningIcon: {
    fontSize: '2.5rem',
  },
  ModalTitle: {
    marginBottom: '10px',
  },
  ModalAction: {
    width: '160px',
    textTransform: 'unset',
    margin: '0 10px',
  },
  ModalActionContinue: {
    color: theme.palette.neutral.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  ModalBody: {
    marginBottom: '20px',
  },
}));

export default function WarningModal(props: SmarterModalProps) {
  const {
    open,
    closeable = true,
    classes,
    handleClose,
    Title,
    isShowIcon,
    children,
    handleAccept,
    Action,
  } = props;
  const ownClasses = useStyles({ closeable });

  return (
    <Modal open={open}>
      <Paper
        className={clsx(ownClasses.MUIPaper, {
          [generateClassName(classes?.MUIPaper)]: classes?.MUIPaper,
        })}
      >
        {closeable && (
          <Box display="flex" justifyContent="end" onClick={handleClose}>
            <IconButton component="span" aria-label="close modal">
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          component="div"
          className={clsx(ownClasses.MUIBox, {
            [generateClassName(classes?.MUIBox)]: classes?.MUIBox,
          })}
        >
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <div className={ownClasses.ModalTitle}>{Title && Title}</div>
            {isShowIcon && (
              <div className="SmarterModal-warningIcon">
                <WarningIcon
                  className={clsx(ownClasses.MUIWarningIcon, {
                    [generateClassName(classes?.MUIWarningIcon)]: classes?.MUIWarningIcon,
                  })}
                  color="secondary"
                />
              </div>
            )}
            <div
              className={clsx(ownClasses.ModalBody, {
                [generateClassName(classes?.ModalBody)]: classes?.ModalBody,
              })}
            >
              {children && children}
            </div>
            <Box
              className="SmarterModal-action"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {Action ? (
                Action
              ) : (
                <>
                  <Button
                    className={clsx(ownClasses.ModalAction)}
                    variant="contained"
                    disableElevation={true}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={clsx(ownClasses.ModalAction, ownClasses.ModalActionContinue)}
                    variant="contained"
                    color="primary"
                    disableElevation={true}
                    onClick={handleAccept}
                  >
                    Continue
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}
