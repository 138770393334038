export const DESIGN_SYSTEM_NAME = 'Design System';

export const PALETTE_COLORS = {
  main: 'main',
  light: 'light',
  dark: 'dark',
  transparent: 'transparent',
  '100': '100',
  '200': '200',
  '300': '300',
  '400': '400',
  '500': '500',
  '600': '600',
  '700': '700',
  '800': '800',
  lightTransparent: 'lightTransparent',
};

export const MAX_SIZE = 5 * 1024 * 1024; // 5MB
