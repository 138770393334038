import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import clsx from 'clsx';
import { SidebarProps } from '../../components/Sidebar/Sidebar';
import { HeaderProps } from '../../components/Header/interface';

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    position: 'relative',
    minHeight: '100vh',
    paddingTop: theme.spacing(4),
  },
  content: {
    padding: `${theme.spacing(7)}px ${theme.spacing(2)}px`,
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '1200px',
      padding: `${theme.spacing(7)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1500px',
    },
  },
}));

type MasterLayoutProps = {
  headerProps?: Partial<HeaderProps>;
  sideBarProps?: Partial<SidebarProps>;
  children?: JSX.Element;
  classes?: any;
  hideFooter?: boolean;
};

export default function MasterLayout({
  headerProps,
  sideBarProps,
  classes,
  hideFooter = false,
  children,
}: MasterLayoutProps) {
  const ownClasses = useStyles();

  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div className={ownClasses.layout}>
      <Header onMenuClick={() => setShowSidebar(!showSidebar)} {...headerProps} isFixedPosition />
      <div
        className={clsx({ [ownClasses.container]: true, [classes?.container]: classes?.container })}
      >
        <Sidebar
          open={showSidebar}
          onBackdropClick={() => setShowSidebar(!showSidebar)}
          classes={classes?.sidebar}
          onClose={() => setShowSidebar(false)}
          {...sideBarProps}
        />
        <main
          className={clsx({
            [ownClasses.content]: true,
            [classes?.content]: classes?.content,
          })}
        >
          {children}
        </main>
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
}
