import { useEffect, useState } from 'react';
import { getMetadata, MetadataProps, GET_DATA_FROM } from './utils';

export default function useMetadata({ key, token }: MetadataProps) {
  const [metadata, setMetadata] = useState({});
  const [getDataFrom, setGetDataFrom] = useState(GET_DATA_FROM.COOKIES);
  const [role, setRole] = useState({
    isMaker: false,
    isDoctor: false,
    isPatient: false,
    isReviewer: false,
    isChecker: false,
    isManager: false,
    isProvider: false,
    isProviderPafCreator: false,
  });
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    async function fetchMetadata() {
      return await getMetadata({ key, token });
    }
    fetchMetadata()
      .then((res) => {
        if (res?.value) {
          setMetadata(res.value);
          setGetDataFrom(res.getDataFrom);
          if (res.role) {
            setRole(res.role);
          }
          setIsFetched(true);
        }
      })
      .catch(() => {
        console.log('Failed to fetch metadata.');
      });
  }, [token]);

  return { getMetadata, metadata, getDataFrom, GET_DATA_FROM, isFetched, ...role };
}
