import { makeStyles, useTheme, Button } from '@material-ui/core';
import {
  Pagination as MuiPagination,
  PaginationItem,
  PaginationProps,
  PaginationRenderItemParams,
} from '@material-ui/lab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { forwardRef } from 'react';

type ExtendedPaginationRenderItemParams = {
  'aria-label'?: string;
} & PaginationRenderItemParams;
export const Pagination = forwardRef((props: PaginationProps, ref) => {
  const classes = usePaginationStyles();
  const theme = useTheme();

  return (
    <MuiPagination
      ref={ref}
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderItem={(props: ExtendedPaginationRenderItemParams) => {
        if (props.type === 'previous' || props.type === 'next') {
          return (
            <Button
              onClick={props.onClick}
              className={classes.navButton}
              disabled={props.disabled}
              aria-label={props['aria-label']}
              style={{
                cursor: props.disabled ? 'auto' : 'pointer',
                color: props.disabled ? theme.palette.shades[400] : theme.palette.primary.main,
              }}
            >
              {props.type === 'previous' ? (
                <>
                  <ChevronLeftIcon /> <span className={classes.navButtonText}>Previous Page</span>
                </>
              ) : (
                <>
                  <span className={classes.navButtonText}>Next Page</span> <ChevronRightIcon />
                </>
              )}
            </Button>
          );
        }
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <PaginationItem
            {...props}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          />
        );
      }}
      color="primary"
      shape="rounded"
    ></MuiPagination>
  );
});

/**
 * It accept "@material-ui/lab/Pagination" props.
 * */
Pagination.propTypes = {};

const usePaginationStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.shades.dark,
    margin: '0 6px',
  },
  selected: {
    color: theme.palette.shades.light,
  },
  navButton: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  navButtonText: {
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
}));
