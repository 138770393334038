import { FC } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { NotFoundImageBase64 } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins, sans-serif !important',
  },
  image: {
    height: '180px',
  },
  title: {
    fontWeight: 700,
    fontSize: `${theme.spacing(4.2)}px`,
    lineHeight: `${theme.spacing(5.6)}px`,
    margin: `${theme.spacing(3.5)}px 0px`,
    fontFamily: 'Playfair Display, sans-serif !important',
  },
  content: {
    fontWeight: 400,
    fontSize: `${theme.spacing(2.2)}px`,
    lineHeight: `${theme.spacing(3.3)}px`,
  },
  button: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(6)}px`,
    fontWeight: 500,
    fontSize: `${theme.spacing(1.8)}px`,
  },
}));

export interface PageNotFoundProps {
  onBackToHomePage: () => void;
  isLoggedIn?: boolean;
}

const PageNotFound: FC<PageNotFoundProps> = ({ isLoggedIn, onBackToHomePage }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={NotFoundImageBase64} alt="404" className={classes.image} />

      <p className={classes.content}>The page you are looking for is not available</p>
      <Button
        data-testid="userApp-notFound_backButton"
        style={{ backgroundColor: '#161616', color: '#fff' }}
        onClick={onBackToHomePage}
        className={classes.button}
      >
        {isLoggedIn ? 'Back to Homepage' : 'Back to Login'}
      </Button>
    </div>
  );
};

export default PageNotFound;
