import React, { Ref } from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: (props: any) => ({
    pointerEvents: props.disabled && 'none',
  }),
}));

type SmarterNumericProps = {
  inputRef?: Ref<any> | undefined;
  InputProps?: any;
  maxValue: number;
  minValue: number;
  value: string;
  thousandSeparator?: ',' | undefined;
  decimalSeparator?: '.' | undefined;
  allowNegative?: false | boolean;
  setValue?: () => void;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  suffix?: any;
  onChange: (arg0: string) => void;
  disabled?: boolean;
  textAlign: any;
};

export default function SmarterNumeric({
  inputRef,
  InputProps,
  maxValue,
  minValue,
  value,
  thousandSeparator = ',',
  decimalSeparator = '.',
  allowNegative = false,
  setValue,
  variant = 'outlined',
  suffix = null,
  onChange,
  disabled = false,
  textAlign = 'left',
  ...props
}: SmarterNumericProps) {
  const classes = useStyles({ disabled });
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.replace('$', '').replace(',', '') || '');
  };

  return (
    <NumberFormat
      variant={variant}
      fullWidth
      inputRef={inputRef}
      value={value}
      onChange={handleOnChange}
      disabled={disabled}
      customInput={TextField}
      InputProps={{
        inputProps: {
          min: minValue,
          max: maxValue,
          style: { textAlign },
        },
      }}
      className={classes.root}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowNegative={allowNegative}
      {...props}
    />
  );
}
