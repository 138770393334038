export function equalsIgnoreCase(str1: string, str2: string) {
  return (
    str1?.localeCompare(str2, undefined, {
      sensitivity: 'base',
    }) === 0
  );
}

export function indexOfIgnoreCase(list: string[], str: string) {
  let convertedList = list.map((word) => {
    return word?.toLowerCase();
  });
  return convertedList.indexOf(str?.toLowerCase()) !== -1;
}
