import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import Row from './Row';
import Node, { INode } from './Node';

interface VirtualizedTreeProps {
  items: {
    nodeDetails: {
      icd10Code?: string;
      icd10Description?: string;
      code?: string;
      description?: string;
    };
  }[];
  highlight: string;
  showCode?: boolean;
  width?: number;
  height: number;
  rowHeight?: number;
  onRowClick: (code: string, description: string) => void;
}
const VirtualizedTree: FC<VirtualizedTreeProps> = ({
  items,
  width = 500,
  height = 400,
  rowHeight = 50,
  onRowClick,
  highlight,
  showCode,
}) => {
  const [openedNodes, setOpenedNodes] = useState<INode[]>([]);
  const listRef = useRef<List>();
  const sizeMap = useRef({});
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef?.current?.resetAfterIndex(index);
  }, []);
  const getSize = (index) => sizeMap.current[index] || rowHeight;
  useEffect(() => {
    if (items?.length) {
      Node.setNodes(items);
      setOpenedNodes(Node.getRootNodes());
    }
  }, [items]);

  const rowRenderer = ({ index, style }) => {
    const node: INode = openedNodes[index];
    if (!node) {
      return null;
    }
    return (
      <Row
        key={node.id}
        index={index}
        style={style}
        item={node}
        onExpand={handleExpand}
        onClick={onRowClick}
        highlight={highlight}
        showCode={showCode}
        setSize={setSize}
      />
    );
  };

  const handleExpand = (node, index) => {
    if (node.isOpen) {
      collapseNode(node, index, openedNodes);
      node.isOpen = false;
    } else {
      expandNode(node, index, openedNodes);
      node.isOpen = true;
    }
    setOpenedNodes([...openedNodes]);
  };

  const expandNode = (currentNode, index, nodes) => {
    const children = currentNode.children;
    const isLastBranch = currentNode.isLastBranch || (currentNode.isRoot && currentNode.isLastNode);
    return nodes.splice(
      index + 1,
      0,
      ...children.map((item) => {
        item.level = currentNode.level + 1;
        item.isOpen = false;
        item.isLastBranch = isLastBranch;
        item.isLeaf = !item.children.length;
        return item;
      }),
    );
  };

  const collapseNode = (currentNode, index, nodes) => {
    let nextNodeIndex = nodes.length;
    for (let i = index + 1; i < nodes.length; i++) {
      if (nodes[i].level <= currentNode.level) {
        nextNodeIndex = i - 1;
        break;
      }
    }
    return nodes.splice(index + 1, nextNodeIndex - index);
  };
  return (
    <List
      ref={listRef}
      width={width}
      height={height}
      itemSize={getSize}
      itemCount={openedNodes.length}
    >
      {({ index, style }) => rowRenderer({ index, style })}
    </List>
  );
};

export default VirtualizedTree;
