import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const customInit = (dsn: string, options: object) => {
  return Sentry.init({
    dsn: dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost'],
      }),
    ],
    tracesSampleRate: 1.0,
    // TECH-1086 Set env value by domain instead of hardcode
    environment: window?.location?.host || 'local',
    ...options,
  });
};
// re-export everything
export * from '@sentry/react';
export { customInit as init };
