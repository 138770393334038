import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CustomizedRadiosProps } from './interface';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    position: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      '&::before': {
        borderColor: theme.palette.primary.main,
      },
    },
    '&::before': {
      border: '1px solid #C4C4C4',
      borderRadius: '4px',
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  icon: {
    width: 0,
    height: 14,
    marginRight: 3,
    marginLeft: 3,
    position: 'static',
    right: '12px',
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' viewBox='0 0 14 10' fill='none'%3E%3Cpath d='M1 3.58065L3.39968 7.19307C3.89815 7.94345 4.14738 8.31864 4.50787 8.41795C4.60904 8.44582 4.71398 8.45759 4.81881 8.45283C5.19234 8.43587 5.51851 8.12523 6.17085 7.50395L13 1' stroke='%234126CF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 3.58065L3.39968 7.19307C3.89815 7.94345 4.14738 8.31864 4.50787 8.41795C4.60904 8.44582 4.71398 8.45759 4.81881 8.45283C5.19234 8.43587 5.51851 8.12523 6.17085 7.50395L13 1' stroke='%2371E3C7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 3.58065L3.39968 7.19307C3.89815 7.94345 4.14738 8.31864 4.50787 8.41795C4.60904 8.44582 4.71398 8.45759 4.81881 8.45283C5.19234 8.43587 5.51851 8.12523 6.17085 7.50395L13 1' stroke='${encodeURIComponent(
      theme.palette.primary.main,
    )}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    width: 14,
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <>
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    </>
  );
}
const useRadioLabelStyle = makeStyles((theme) => ({
  root: {
    paddingTop: 13,
    paddingBottom: 13,
    justifyContent: 'center',
    marginLeft: 0,
    flex: '1 auto',
    position: 'relative',
    '&:last-child': {
      marginRight: '0',
    },
    '& >.Mui-checked + .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
    },
    '& >.MuiTypography-body1': {
      fontSize: 16,
      lineHeight: '24px',
      color: '#737373',
    },
  },
}));
const RadioLabel = (props) => {
  const classes = useRadioLabelStyle();
  return <FormControlLabel classes={classes} {...props} />;
};

const useRadioStyles = makeStyles({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 1fr',
  },
  helperText: {
    color: '#ED5E58',
  },
});

export const CustomizedRadios = ({
  name,
  options,
  inputProps,
  value,
  ...props
}: CustomizedRadiosProps) => {
  const classes = useRadioStyles();

  return (
    <>
      <RadioGroup
        aria-label={name}
        name={name}
        className={classes.root}
        value={value}
        {...inputProps}
        {...props}
      >
        {options.map((item, pos) => {
          return (
            <RadioLabel key={pos} value={item.value} control={<StyledRadio />} label={item.label} />
          );
        })}
      </RadioGroup>
    </>
  );
};
