import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { PALETTE_COLORS } from '../../utils';

/**
 * It accept "@material-ui/core/TextField" props.
 * */
type SearchTextFieldProps = {
  color: 'primary' | 'secondary' | 'error' | 'success';
  placeholder?: string;
  classes?: any;
  InputProps?: any;
  inputProps?: any;
} & TextFieldProps;
export type Ref = HTMLDivElement;
const SearchTextField = ({
  classes = {},
  placeholder = 'Search by name, type, keywords...',
  color = 'primary',
  InputProps = {},
  inputProps = {},
  ...props
}: SearchTextFieldProps) => {
  const ownClasses = useSearchBarStyles({ color });
  const newRootClasses = {
    ...classes,
    root: clsx(classes.root, ownClasses.root),
  };

  return (
    <TextField
      InputProps={{
        ...InputProps,
      }}
      fullWidth
      {...props}
      placeholder={placeholder}
      inputProps={{
        ...inputProps,
        className: clsx(ownClasses.input, inputProps.className),
      }}
      variant="outlined"
      classes={newRootClasses}
    />
  );
};

interface StyleProps {
  color: 'primary' | 'secondary' | 'error' | 'success';
}
const useSearchBarStyles = makeStyles((theme) => ({
  input: {
    fontSize: 14,
  },
  root: {
    backgroundColor: theme.palette.shades[PALETTE_COLORS['100']],
    borderRadius: theme.shape.borderRadius,

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 6%)',
      },
      '&.Mui-focused fieldset': {
        boxShadow: (props: StyleProps) =>
          `0px 3px 3px -2px ${
            theme.palette[props.color].main
          }, 0px 2px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 6%)`,
      },
    },
  },
}));
export default SearchTextField;
