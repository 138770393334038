import React from 'react';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SmarterPopperProps } from './interface';

const useStyles = makeStyles(() => ({
  root: {
    width: '500px',
    maxWidth: '87%',
    minHeight: '340px',
    padding: '20px',
    maxHeight: '270px',
  },
}));

const SmarterPopover = React.forwardRef(
  ({ id, anchorEl = null, open, onClose, ...props }: SmarterPopperProps) => {
    const classes = useStyles();
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        marginThreshold={25}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.root }}
      >
        {props.children}
      </Popover>
    );
  },
);
export default SmarterPopover;
