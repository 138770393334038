/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
export interface INode {
  id: string | number;
  nodeDetails: {
    icd10Code?: string;
    icd10Description?: string;
    code?: string;
    description?: string;
  };
  children: INode[];
  level: number;
  isOpen: boolean;
  isLeaf: boolean;
  isLastNode: boolean;
  isLastBranch: boolean;
}
export default class Node {
  static nodes: INode[] = [];
  static FIRST_LEVEL = 1;

  _leaf = false;

  _lastNode = false;

  _lastBranch = false;

  _open = true;

  _level = Node.FIRST_LEVEL;

  constructor(item, isLeaf) {
    this.isLeaf = isLeaf;

    ['nodeDetails', 'children'].forEach((prop) => {
      Object.defineProperty(this, prop, {
        get: () => item[prop],
      });
    });
  }

  get level() {
    return this._level;
  }

  get isOpen() {
    return this._open;
  }

  get isLeaf() {
    return this._leaf;
  }

  get isLastNode() {
    return this._lastNode;
  }

  get isLastBranch() {
    return this._lastBranch;
  }

  set level(value) {
    this._level = value;
  }

  set isOpen(value) {
    this._open = value;
  }

  set isLeaf(value) {
    this._leaf = value;
  }

  set isLastNode(value) {
    this._lastNode = value;
  }

  set isLastBranch(value) {
    this._lastBranch = value;
  }

  static setNodes(items) {
    Node.nodes = items.map((item) => {
      const isLeaf = !item?.children?.length;
      return new Node(item, isLeaf);
    });
  }

  static getRootNodes() {
    const rootNodes: INode[] = [];
    let index = 0;

    while (index < Node.nodes.length) {
      const currentNode: INode = Node.nodes[index];
      rootNodes.push(currentNode);
      if (currentNode?.children?.length > 0) {
        Node.nodes.splice(
          index + 1,
          0,
          ...((currentNode?.children || [])?.map((item) => {
            item.level = currentNode.level + 1;
            item.isOpen = true;
            item.isLeaf = !item.children.length;
            return item;
          }) || []),
        );
      }
      index++;
    }
    rootNodes[rootNodes.length - 1].isLastNode = true;

    return rootNodes;
  }
}
